<template lang="pug">
img(ref='img', src='~@/assets/scacchiera.jpg', style='display:none')
</template>

<script>
import createREGL from 'regl';
import createCamera from 'regl-camera';

import vert from '@/shaders/curved-panel.vert';
import frag from '@/shaders/curved-panel.frag';
import { onBeforeUnmount, onMounted, ref } from 'vue';

export default {
  name: 'CurvedPanel',
  setup() {
    const position = [];
    const uv = [];
    const nSegments = 50;
    const startX = -1;
    const endX = 1;

    for (let x = startX; x <= endX; x += 2 / nSegments) {
      const z = -Math.sqrt(1 - x ** 2);
      position.push([x, -1, z], [x, 1, z]);
      uv.push([x * 0.5 + 0.5, 0], [x * 0.5 + 0.5, 1]);
    }

    console.log(position);

    const img = ref(null);

    const regl = createREGL();
    const camera = createCamera(regl, {
      center: [0, 0, 0],
      distance: 0,
      damping: 0,
      theta: 0,
      phi: 0,
      fovy: Math.PI / 2,
    });

    onMounted(() => {
      let texture;

      img.value.addEventListener('load', () => {
        texture = regl.texture(img.value);
      });

      const BLACK = regl.texture(new Array(4).map(() => [0, 0, 0]));
      const draw = regl({
        vert,
        frag,
        uniforms: {
          time: regl.context('time'),
          texture: (context, prop) => {
            return prop.texture || BLACK;
          },
        },
        attributes: {
          position: regl.buffer(position),
          uv: regl.buffer(uv),
        },
        count: position.length,
        primitive: 'triangle strip',
      });

      regl.frame(() => {
        camera(() => {
          regl.clear({ color: [0, 0, 0, 1] });
          draw({ texture });
        });
      });
    });

    onBeforeUnmount(() => regl.destroy());

    return { img };
  },
};
</script>
